import { TranslationKeys } from './keys/keys';
import { detectLanguage } from './language';
import { Spanish } from './keys/spanish';
import { English } from './keys/english';

export const translate = (key: keyof TranslationKeys, args: string[] = []): string => {
  const language = detectLanguage();
  let translation = language === 'spanish' ? Spanish[key] : English[key];

  args.forEach((arg, index) => {
    translation = translation.replace('%s', arg);
  });

  return translation;
}
import { ClientResponse, sendRequest } from './sendRequest';
import { Competition } from 'types';

export class CompetitionClient {
  public static async fetchAll(): Promise<ClientResponse<Competition[]>> {
    const resp = await sendRequest<{}, Competition[]>('GET', 'competition/all');
    if (resp.error) {
      return {error: resp.error} as ClientResponse<Competition[]>;
    }

    return {error: '', response: resp.response}
  }
}
import { Box, keyframes } from '@chakra-ui/react';
import wave from '../../assets/images/wave.svg';
import React from 'react';

const waveAnimation = keyframes`
    0% {
        transform: translateX(0);
    }
    100% {
        transform: translateX(-50%);
    }
`;

const swellAnimation = keyframes`
    0%, 100% {
        transform: translate3d(0, -5px, 0);
    }
    50% {
        transform: translate3d(0, 5px, 0);
    }
`;

export const Wave: React.FC = () => {
  return (
    <Box
      position="relative"
      width="100%"
      height="40vh"
      overflow="hidden"
      marginTop="auto"
      opacity="0.5"
    >
      <Box
        background={`url("${wave}") repeat-x`}
        position="absolute"
        bottom="0"
        width="200%"
        height="40vh"
        animation={`${waveAnimation} 40s linear infinite`}
        backgroundSize="150% 150%"
      />
      <Box
        background={`url("${wave}") repeat-x`}
        position="absolute"
        bottom="0"
        width="200%"
        height="40vh"
        animation={`${waveAnimation} 30s linear infinite, ${swellAnimation} 10s ease infinite`}
        opacity="0.5"
        backgroundSize="150% 150%"
      />
    </Box>
  );
};
import React from 'react';
import { Box, Flex, Text } from '@chakra-ui/react';
import { Wave } from '../../components/utils';
import { Button, IconLogo } from '../../components/base';
import { Link } from 'react-router-dom';
import { translate } from '../../core/translation';
import { TextLogo } from '../../components/base/TextLogo';

export const Intro: React.FC = () => {
  return (
    <Flex direction={"column"} width='100%' gap={20}>
      <Flex width='100%' justifyContent='center'>
        <TextLogo/>
      </Flex>
      <Flex width='100%' justifyContent='center' marginTop={'12px'}>
        <Box width='50%'>
          <IconLogo/>
        </Box>
      </Flex>
      <Flex justifyContent={"center"} textAlign={"center"} direction={"column"} gap={2}>
        <Text fontSize={"md"}>{translate('INTRO_TEXT_MAIN')}</Text>
        <Text fontSize={"sm"}>{translate('INTRO_TEXT_SUB')}</Text>
      </Flex>
      <Flex direction="column" gap={2}>
        <Link to='/signin'>
          <Button type={'primary'} size={'full'}>{translate('INTRO_SIGNIN_BUTTON')}</Button>
        </Link>
        <Link to='/signup'>
          <Button type={'secondary'} size={'full'}>{translate('INTRO_SIGNUP_BUTTON')}</Button>
        </Link>
      </Flex>
      <Flex width="full"  position="fixed" bottom="0" zIndex="-1" left="0" justifyContent={"center"}>
        <Wave />
      </Flex>
    </Flex>
  )
}
import { TranslationKeys } from './keys';

export const English: TranslationKeys = {
  // HEADER
  BACK: 'Back',
  // INTRO
  INTRO_TEXT_MAIN: 'Show the world your football knowledge!',
  INTRO_TEXT_SUB: 'Login now or register to play',
  INTRO_SIGNIN_BUTTON: 'I already have an account',
  INTRO_SIGNUP_BUTTON: "I don't have an account yet",
  INTRO_USERNAME_PLACEHOLDER: "Your username",
  INTRO_EMAIL_PLACEHOLDER: "Your email address",
  INTRO_PASSWORD_PLACEHOLDER: "Your password",
  SIGNUP_TEXT_MAIN: "Join the football community",
  SIGNUP_TEXT_SUB: "Register now and share your knowledge with the world",
  SIGNUP_BUTTON_TEXT: "Register now",
  SIGNUP_CHOOSE_COMPETITIONS: 'Choose your favourite competitions',
  SIGNIN_TEXT_MAIN: 'Sign in',
  SIGNIN_TEXT_SUB: 'Sign in now to continue playing with your account',
  SIGNIN_BUTTON_TEXT: 'Continue',
  // HOME
  FACT_CARD_HEADER: 'Did you know?',
  RANKING: 'Ranking',
  RANKING_CARD_DESCRIPTION: "You're currently at %s division. Keep playing to promote!",
  RANKING_CARD_CTA: 'See ranking',
  // QUICK SESSION
  QUESTION: 'Question',
  QUESTION_CARD_ADVICE: 'Read carefully and answer',
  QUICK_SESSION: 'Quick Session',
  QUICK_SESSION_SUMMARY_STATS: 'Your session stats',
  QUICK_SESSION_QUESTIONS_STAT: 'Questions',
  QUICK_SESSION_HITS_STAT: 'Hits',
  QUICK_SESSION_FAILURES_STAT: 'Failures',
  QUICK_SESSION_SUMMARY_STATEMENT: 'Statement',
  QUICK_SESSION_SUMMARY_CHOICE: 'Choice',
  QUICK_SESSION_SUMMARY_ANSWER: 'Answer',
  QUICK_SESSION_SUMMARY_HIT: 'Hit',
  QUICK_SESSION_SUMMARY_CONTINUE: 'Continue to Home',
  QUICK_SESSION_CARD_DESCRIPTION: 'Face a round of 10 questions in which you will have 10 seconds to answer each question. Can you get the highest score?',
  QUICK_SESSION_CARD_CTA: 'Play now',
  // CATEGORIES
  TEAM_HISTORY: 'Team history 📚',
  ASSISTANTS: 'Ball wizards 🧙‍♂️',
  WARRIORS: 'Warriors on the pitch ⚔️',
  SCORERS: 'Goalscorers ⚽',
  // DIVISIONS
  ROOKIE: 'Rookie',
  AMATEUR: 'Amateur',
  SEMI_PRO: 'Semi-Pro',
  PRO: 'Pro',
  ALL_STAR: 'All Star',
  MVP: 'MVP',
  CHAMPION: 'Champion',
  LEGEND: 'Legend',
  HALL_OF_FAME: 'Hall of Fame',
  // Errors
  GENERIC_ERROR_TEXT: 'Something went wrong',
  'shared.email_value_object.invalid_email': 'Invalid email address',
  "source.legacyfootball.user.user_not_found": 'Invalid credentials',
  "source.legacyfootball.user.method.invalid_credentials": 'Invalid credentials'
}
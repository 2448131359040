import { FC } from 'react';
import { Box, Flex } from '@chakra-ui/react';

export interface CompetitionToggleProps  {
  id: number;
  name: string;
  flag: string;
  isChecked: boolean;
  onClick: (competitionId: number) => void
}

export const CompetitionToggle: FC<CompetitionToggleProps> = ({id, name, flag, isChecked, onClick}) => {
  return (
    <Flex
      minWidth={'130px'}
      height={'100px'}
      p={4}
      background={ isChecked ? 'orange.400' : 'orange.900'}
      direction={'column'}
      alignItems={'center'}
      justifyContent={'center'}
      gap={2}
      borderRadius={'20px'}
      cursor={'pointer'}
      onClick={() => onClick(id)}
      transition={'background 0.3s ease'}
    >
      <Box width={'30px'} height={'30px'} bg={'orange.500'} borderRadius={'50%'} textAlign={'center'} display='flex' justifyContent='center' alignItems='center' verticalAlign={'center'}>
        {flag}
      </Box>
      {name}
    </Flex>
  )
}
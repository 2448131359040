"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.DivisionThreshold = void 0;
const Division_1 = require("./Division");
exports.DivisionThreshold = {
    [Division_1.Division.ROOKIE]: 0,
    [Division_1.Division.AMATEUR]: 1000, // 1k points
    [Division_1.Division.SEMI_PRO]: 7000, // 7k points
    [Division_1.Division.PRO]: 15000, // 15k points
    [Division_1.Division.ALL_STAR]: 50000, // 50k points
    [Division_1.Division.MVP]: 100000, //100K Points
    [Division_1.Division.CHAMPION]: 250000, // 250K points
    [Division_1.Division.LEGEND]: 500000, //500 K points
    [Division_1.Division.HALL_OF_FAME]: 1000000 // 1M points
};

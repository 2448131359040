import { TranslationKeys } from './keys';

export const Spanish: TranslationKeys = {
  // HEADER
  BACK: 'Atrás',
  // INTRO
  INTRO_TEXT_MAIN: '¡Demuestra tu conocimiento de fútbol!',
  INTRO_TEXT_SUB: 'Inicia sesión ahora o regístrate para jugar',
  INTRO_SIGNIN_BUTTON: 'Ya tengo una cuenta',
  INTRO_SIGNUP_BUTTON: "No tengo una cuenta todavía",
  INTRO_USERNAME_PLACEHOLDER: "Nombre de usuario",
  INTRO_EMAIL_PLACEHOLDER: "Correo electrónico",
  INTRO_PASSWORD_PLACEHOLDER: "Contraseña",
  SIGNUP_TEXT_MAIN: "Únete a la comunidad del fútbol",
  SIGNUP_BUTTON_TEXT: "Registrar",
  SIGNUP_TEXT_SUB: "Regístrate ahora y disfruta comparte tu conocimiento con el mundo.",
  SIGNUP_CHOOSE_COMPETITIONS: 'Selecciona tus competiticiones favoritas',
  SIGNIN_TEXT_MAIN: 'Iniciar sesión',
  SIGNIN_TEXT_SUB: 'Inicia sesión ahora para continuar jugando con tu cuenta',
  SIGNIN_BUTTON_TEXT: 'Continuar',
  // HOME
  FACT_CARD_HEADER: '¿Lo sabías?',
  RANKING: 'Clasificación',
  RANKING_CARD_DESCRIPTION: 'Te encuentras en la división %s. ¡Sigue jugando para ascender!',
  RANKING_CARD_CTA: 'Ver clasificación',
  // QUICK SESSION
  QUESTION: 'Pregunta',
  QUESTION_CARD_ADVICE: 'Lee atentamente y responde',
  QUICK_SESSION: 'Sesión Rápida',
  QUICK_SESSION_SUMMARY_STATS: 'Estadísticas de la sesión',
  QUICK_SESSION_QUESTIONS_STAT: 'Preguntas',
  QUICK_SESSION_HITS_STAT: 'Aciertos',
  QUICK_SESSION_FAILURES_STAT: 'Fallos',
  QUICK_SESSION_SUMMARY_STATEMENT: 'Pregunta',
  QUICK_SESSION_SUMMARY_CHOICE: 'Elección',
  QUICK_SESSION_SUMMARY_ANSWER: 'Respuesta',
  QUICK_SESSION_SUMMARY_HIT: 'Acierto',
  QUICK_SESSION_SUMMARY_CONTINUE: 'Continuar',
  QUICK_SESSION_CARD_DESCRIPTION: 'Enfréntate a una ronda de 10 preguntás rápidas. Sólo tendrás 10 segundos para responder. ¿Podrás conseguir la máxima puntuación?',
  QUICK_SESSION_CARD_CTA: 'Jugar',
  // CATEGORIES
  TEAM_HISTORY: 'Historia de equipos 📚',
  ASSISTANTS: 'Magos del balón 🧙‍♂️',
  WARRIORS: 'Guerreros del campo ⚔️',
  SCORERS: 'Goleadores ⚽',
  // DIVISIONS
  ROOKIE: 'Rookie',
  AMATEUR: 'Amateur',
  SEMI_PRO: 'Semi-Pro',
  PRO: 'Pro',
  ALL_STAR: 'All Star',
  MVP: 'MVP',
  CHAMPION: 'Campeón',
  LEGEND: 'Leyenda',
  HALL_OF_FAME: 'Hall de la fama',
  // Errors
  GENERIC_ERROR_TEXT: 'Algo no ha ido bien',
  'shared.email_value_object.invalid_email': 'Email inválido',
  'source.legacyfootball.user.user_not_found': 'Credenciales inválidas',
  'source.legacyfootball.user.method.invalid_credentials': 'Credenciales inválidas'
}
import { ChallengerClient, sendRequest } from '../client';
import { GetIdentityTokenByEmailPasswordArgs } from 'types';
import { setCookie } from '../utils';
import { AUTH_COOKIE_NAME } from './getAuthToken';
import { Dispatch } from 'react';
import { Action, StateReducers } from '../../state/State';
import { getUserId } from './auth';

export async function login(email: string, password: string, dispatch: Dispatch<Action>): Promise<{error?: string}> {
  const response = await sendRequest<GetIdentityTokenByEmailPasswordArgs, string>(
    'POST',
    'auth/login/email-password',
    {
      email,
      password
    }
  );

  if (response.error) {
    return { error: response.error }
  }

  setCookie(AUTH_COOKIE_NAME, response.response, 365);

  const challenger = await ChallengerClient.fetch(getUserId());
  if (challenger.error) {
    throw new Error(challenger.error);
  }

  dispatch({type: StateReducers.SET_CHALLENGER, payload: challenger.response});
  return {};
}
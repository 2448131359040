import { getAuthToken } from '../auth';

export type HTTP_METHOD = 'GET' | 'POST' | 'PUT' | 'DELETE';

export type BackendResponse<T> = {result: T}

export type ClientResponse<T> = {error?: string, response: T}

export async function sendRequest<TBody, TResponse>(
  method: HTTP_METHOD,
  endpoint: string,
  body: TBody | undefined = undefined,
  extraHeaders: Record<string, string> = {}
): Promise<{error?: string, response: TResponse}> {
  const urlBase = process.env.REACT_APP_LF_API_URL ?? '';
  const urlFull = urlBase ? `${urlBase}/${endpoint}` : `/${endpoint}`;

  const authToken = getAuthToken();
  const finalHeaders = authToken
    ? {...extraHeaders, 'Content-Type': 'application/json', 'Authorization': authToken}
    : {...extraHeaders, 'Content-Type': 'application/json'}

  const requestOptions = body
    ? { headers: finalHeaders, body: JSON.stringify(body), method }
    : { headers: finalHeaders, method };

  const response = await fetch(urlFull, requestOptions);

  const json = await response.json() as BackendResponse<TResponse>;
  return {
    error: (json as any).message,
    response: json.result
  };
}
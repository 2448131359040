import React from 'react';
import { Button as ChakraButton, WrapItem } from '@chakra-ui/react';

export interface ButtonProps {
  type: "primary" | "secondary",
  size: "s" | "m" | "full",
  children: React.ReactNode,
  onClick?: () => void;
}

export const Button: React.FC<ButtonProps> = ({type, size, children, onClick}: ButtonProps) => {
  const primaryButton = () => {
    return <ChakraButton
      width={size === 'full' ? '100%' : 'auto'}
      px={size === 's' ? 8 : 16}
      bg="orange.400"
      _hover={{
        bg: "orange.500",
        color: "black",
      }}
      onClick={onClick}
    >
      {children}
    </ChakraButton>
  }

  const secondaryButton = () => {
    return <ChakraButton
      width={size === 'full' ? '100%' : 'auto'}
      bg="gray.300"
      color="gray.700"
      _hover={{
        bg: "orange.400",
        color: "black",
      }}
      onClick={onClick}
    >
      {children}
    </ChakraButton>
  }

  return (<WrapItem>
    {type === 'primary' ? primaryButton() : secondaryButton()}
  </WrapItem>)
}
import { FC } from 'react';
import { Box, Flex, Text } from '@chakra-ui/react';
import { Button } from './Button';

export interface CardProps {
  headerText: string;
  bodyText: string;
  bgColor?: string;
  bgColorLinear?: string;
  icon?: string;
  cta?: string;
  onClick?: () => void;

}

export const Card: FC<CardProps> = ({headerText, bodyText, bgColor, bgColorLinear, icon, cta, onClick}) => {
  return <Flex
    direction={'column'}
    gap={8}
    bgGradient={`linear(to-r, ${bgColor ?? bgColor}, ${bgColorLinear || bgColor})`}
    borderRadius="md"
    p={'16px'}
    width={'100%'}
    minWidth={'100%'}
    minHeight={'200px'}
    position={"relative"}
    overflow={'hidden'}
    _hover={cta ? { transform: 'scale(1.01)' } : {}}
    cursor={ cta ? 'pointer' : 'auto'}
    onClick={onClick ? () => onClick() : () => console.log('Nothing')}
  >
    {icon && (
      <Box
        position="absolute"
        top="-50"
        right="-10"
        opacity="0.5"
        fontSize="9xl"
        pointerEvents="none"
        transform='rotate(45deg)'
        textShadow="0 0 3px black, 1px 1px 0 black, -1px -1px 0 black, 1px -1px 0 black, -1px 1px 0 black"
      >
        {icon}
      </Box>
    )}
    <Text fontSize={'2xl'} zIndex={1}>{headerText}</Text>
    <Flex textAlign={"center"} zIndex={1} width={'100%'}>
      <Text
        fontSize={'sm'}
        textShadow="0 0 3px black, 1px 1px 0 black, -1px -1px 0 black, 1px -1px 0 black, -1px 1px 0 black"
      >
        {bodyText}</Text>
    </Flex>
    {cta && <Button type={'secondary'} size={'full'}>{cta}</Button>}
  </Flex>
}
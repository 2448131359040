import { FC } from 'react';
import { Box, Flex, Text } from '@chakra-ui/react';
import { FullLogo } from '../base';
import { translate } from '../../core/translation';
import { QuestionCategory } from 'types';

export interface QuestionCardProps {
  sentence: string,
  category?: QuestionCategory
}

export const QuestionCard: FC<QuestionCardProps> = ({sentence, category}) => {
  const getCategoryText = (): string => {
    if (category === QuestionCategory.TEAM_HISTORY) {
      return translate('TEAM_HISTORY')
    }

    if (category === QuestionCategory.ASSISTANTS) {
      return translate('ASSISTANTS')
    }

    if (category === QuestionCategory.WARRIORS) {
      return translate('WARRIORS')
    }

    return translate('SCORERS')
  }

  const getCategoryColor = (): string => {
    if (category === QuestionCategory.TEAM_HISTORY) {
      return 'yellow.600';
    }

    if (category === QuestionCategory.ASSISTANTS) {
      return 'green.700';
    }

    if (category === QuestionCategory.WARRIORS) {
      return 'red.700';
    }

    return 'blue.700';
  }

  return <Flex
    direction={'column'}
    background={'orange.600'}
    rounded={'2xl'}
    justifyContent={'space-between'}
    color={'gray.100'}
    minHeight={'200px'}
  >
    <Flex justifyContent={'center'} paddingX={4} paddingY={1} roundedTop={'2xl'} textAlign={'end'} background={getCategoryColor()}>
      <Text size={'sm'}>{getCategoryText()}</Text>
    </Flex>
    <Flex direction={'column'} padding={6} gap={4}>
      <Text fontStyle={'italic'} fontSize={'sm'}>{translate('QUESTION_CARD_ADVICE')}</Text>
      <Text>{sentence}</Text>
    </Flex>
    <Box p={1} roundedBottom={'2xl'} textAlign={'end'} marginTop={4} background={'gray.900'}>
      <FullLogo size={'sm'}/>
    </Box>
  </Flex>
}
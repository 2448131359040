import { useToast } from '@chakra-ui/react';
import { translate } from '../core/translation';
import { TranslationKeys } from '../core/translation/keys/keys';

export const useErrorToast = () => {
  const toast = useToast();

  const defaultDescription = translate('GENERIC_ERROR_TEXT');

  return ({
    title = 'Ooops!',
    description = 'Something went wrong',
    duration = 5000,
    isClosable = true,
    ...rest
    }) => {
    const translation = translate(description as keyof TranslationKeys);
      toast({
        title,
        description: translation ?? defaultDescription,
        duration,
        isClosable,
        status: 'error',
        ...rest,
    });
  };
};

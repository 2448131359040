import React, { FC, ReactNode } from 'react';
import { Flex } from '@chakra-ui/react';

export interface RowProps {
  children: ReactNode,
  index: number
}
export const Row: FC<RowProps> = ({children, index}) => {
  return <Flex
    justifyContent={'space-between'}
    gap={2}
    paddingY={4}
    paddingX={2}
    background={index % 2 === 0 ? 'gray.600' : 'gray.700'}
    width={'100%'}
  >
    {children}
  </Flex>
}
import { FC } from 'react';
import { Flex, Text } from '@chakra-ui/react';
import { Link } from 'react-router-dom';
import { Button } from '../../base';
import { translate } from '../../../core/translation';
import { Row } from '../../base/Row';
import { QuickSessionResponse } from 'types';

export interface QuickSessionSummaryProps {
  sessionEarnedPoints: number,
  session: QuickSessionResponse
}
export const QuickSessionSummary: FC<QuickSessionSummaryProps> = ({sessionEarnedPoints, session}) => {
  return (
    <Flex justifyContent={'center'} gap={4} direction={'column'} textAlign={'center'}>
      <Flex width={'100%'} justifyContent={'center'}>
      <Link to={'/'}>
      <Button type={'primary'} size={'full'}>
        <Text>{translate('QUICK_SESSION_SUMMARY_CONTINUE')}</Text>
      </Button>
      </Link>
      </Flex>
      <Text fontSize={'xl'}>{translate('QUICK_SESSION_SUMMARY_STATS')}</Text>
      <Text fontSize={'xl'}>🏆 {sessionEarnedPoints}</Text>
      <Flex justifyContent={'space-between'}>
      <Flex direction={'column'} width={'100%'} alignItems={'center'} justifyContent='center'>
        <Text>{translate('QUICK_SESSION_QUESTIONS_STAT')}</Text>
      <Text>{session?.summary?.answeredQuestions.length}</Text>
      </Flex>
      <Flex direction={'column'} width={'100%'} alignItems={'center'} justifyContent='center'>
        <Text>{translate('QUICK_SESSION_HITS_STAT')} ✅</Text>
      <Text>{session?.summary?.hits}</Text>
      </Flex>

      <Flex direction={'column'} width={'100%'} alignItems={'center'} justifyContent='center'>
        <Text>{translate('QUICK_SESSION_FAILURES_STAT')} ❌  </Text>
      <Text>{session?.summary?.failures}</Text>
      </Flex>
      </Flex>
      <Flex direction={'column'}  alignItems={'end'} justifyContent={'end'} textAlign={'start'}>
      <Row index={0}>
      <Flex width={'50%'}>
      <Text fontSize={'smaller'}>{translate('QUICK_SESSION_SUMMARY_STATEMENT')}</Text>
      </Flex>
      <Flex width={'20%'} alignItems={'center'}>
      <Text fontSize={'small'}>{translate('QUICK_SESSION_SUMMARY_CHOICE')}</Text>
      </Flex>
      <Flex width={'20%'} alignItems={'center'}>
      <Text fontSize={'small'}>{translate('QUICK_SESSION_SUMMARY_ANSWER')}</Text>
      </Flex>
      <Flex width={'10%'} alignItems={'center'}>
      <Text fontSize={'small'}>{translate('QUICK_SESSION_SUMMARY_HIT')}</Text>
      </Flex>
      </Row>
      {session?.summary?.answeredQuestions?.map((aq, index) => {
        return <Row index={index + 1} key={`${index}-r`}>
        <Flex width={'50%'}>
        <Text fontSize={'smaller'}>{aq.statement}</Text>
          </Flex>
          <Flex width={'20%'} alignItems={'center'}>
        <Text fontSize={'small'}>{aq.answer === 'not_answered' ? '-' : aq.answer}</Text>
          </Flex>
          <Flex width={'20%'} alignItems={'center'}>
        <Text fontSize={'small'}>{aq.correctAnswer}</Text>
          </Flex>
          <Flex width={'10%'} alignItems={'center'}>
          <Text>{aq.correctAnswer === aq.answer ? '✅' : '❌'}</Text>
          </Flex>
          </Row>
      })}
      </Flex>
  </Flex>
  )
}
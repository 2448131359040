import React, { createContext, Dispatch, FC, useContext, useReducer } from 'react';
import { Action, initState, State, stateReducer } from './State';

const initialState: State = initState();

interface StateContextType {
  state: State;
  dispatch: Dispatch<Action>;
}

const StateContext = createContext<StateContextType | undefined>(undefined);

export const useStateContext = () => {
  const context = useContext(StateContext);
  if (!context) {
    throw new Error('useStateContext must be used within a StateProvider');
  }
  return context;
};

export const StateProvider: FC<{children: React.ReactNode}> = ({children}) => {
  const [state, dispatch] = useReducer(stateReducer, initialState);

  return <StateContext.Provider value={{state, dispatch}}>
    {children}
  </StateContext.Provider>

}
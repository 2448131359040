import { FC } from 'react';
import { Box, Flex, Text } from '@chakra-ui/react';
import { translate } from '../../../core/translation';
import { Timer } from '../Timer';
import { QuestionCard } from '../QuestionCard';
import { Loading } from '../../base/Loading';
import { Button } from '../../base';
import { QuickSessionResponse } from 'types';
import { motion } from 'framer-motion';

export interface QuickSessionGameplayProps {
  session: QuickSessionResponse | null,
  secondsToAnswer: number | null,
  showQuestionCard: boolean,
  isNextQuestionLoading: boolean,
  showIsCorrect: boolean,
  showIsFail: boolean,
  lastQuestionPoints: number,
  sessionEarnedPoints: number,
  onAnswerQuestion: (choice: string) => void
}

const MotionBox = motion(Box);

export const QuickSessionGameplay: FC<QuickSessionGameplayProps> = ({session, secondsToAnswer, showQuestionCard, isNextQuestionLoading, showIsCorrect, showIsFail, onAnswerQuestion, sessionEarnedPoints, lastQuestionPoints}) => {

  const currentQuestion = () => {
    const answeredQuestions =  session?.summary?.answeredQuestions.length || 0;
    return answeredQuestions + 1;
  }

  const totalQuestions = () => {
    const answered = session?.summary?.answeredQuestions.length || 0;
    const remaining = session?.summary?.remainingQuestions || 0;

    return answered + remaining + 1;
  }

  const getPointsEarnedColor = () => {
    if (lastQuestionPoints > 0) {
      return 'green.500';
    }

    if (lastQuestionPoints < 0) {
      return 'red.500';
    }

    return 'gray.200'
  }

  const questionPointsEarnedFn = () => {
    return (
      <MotionBox
        initial={{ y: +50, opacity: 0 }}
        animate={{ y: 0, opacity: 1 }}
        transition={{ duration: 0.5, ease: 'easeOut' }}
      >
        <Box>
          <Text fontSize={'2xl'} color={getPointsEarnedColor()}>{lastQuestionPoints > 0 && '+'}{lastQuestionPoints} 🏆</Text>
        </Box>
      </MotionBox>
    )
  };

  return <>
    {session && <Flex
      direction={'column'}
      gap={2}
      width={'100%'}
      justifyContent={'space-between'}
      alignItems={'center'}
      rounded={'2xl'}
      padding={2}
      background={'gray.800'}
    >
      <Flex>
        <Text fontSize={'lg'}>{`${translate('QUESTION')} ${currentQuestion()}/${totalQuestions()}`}</Text>
      </Flex>
      <Flex gap={4} justifyContent={'center'} alignItems={'center'}>
        <Text>✅ {session.summary?.hits}</Text>
        <Text>❌ {session.summary?.failures}</Text>
        <Text>🏆 {sessionEarnedPoints}</Text>
        <Flex alignItems={'center'} gap={1}>
          <Text fontSize={'xl'}>⏱️</Text>
          {secondsToAnswer && <Timer time={secondsToAnswer}/>}
        </Flex>
      </Flex>
    </Flex>}
    {session && showQuestionCard &&
      <MotionBox
        initial={{ y: -100, opacity: 0 }}
        animate={{ y: 0, opacity: 1 }}
        transition={{ duration: 0.5, ease: 'easeOut' }}
      >
        <QuestionCard sentence={session.question?.statement ?? ''} category={session.question?.category}/>
      </MotionBox>
    }
    {isNextQuestionLoading && <Flex width={'100%'} justifyContent={'center'} padding={4}><Loading/></Flex>}
    {showIsCorrect && <Flex width={'100%'} direction='column' alignItems={'center'} justifyContent={'center'} padding={4}>
      <Box><Text fontSize={'2xl'}>✅ Correct! 👏</Text></Box>
      {questionPointsEarnedFn()}
    </Flex>}
    {showIsFail && <Flex width={'100%'} direction='column' alignItems='center' justifyContent={'center'} padding={4}>
      <Box><Text fontSize={'2xl'}>❌ Error! 😖</Text></Box>
      {questionPointsEarnedFn()}
    </Flex>}
    {!isNextQuestionLoading && !showIsCorrect && !showIsFail && <Flex justifyContent={'space-between'} gap={3} padding={4} direction={'column'}>
      {session?.question && session.question.choices.map((c: string, index: number) => {
        return <Button key={index} type={index % 2 === 0 ? 'primary' : 'secondary'} size={'full'} onClick={() => onAnswerQuestion(c)}>{c}</Button>
      })}
    </Flex>}
  </>
}
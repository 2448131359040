import React, { FC, useEffect, useState } from 'react';
import { Button, FullLogo, HScroll, TextInput } from '../../components/base';
import { Box, Flex, Text } from '@chakra-ui/react';
import { Wave } from '../../components/utils';
import { translate } from '../../core/translation';
import { CompetitionToggle } from '../../components/competition';
import { Competition } from 'types';
import { CompetitionClient, UserClient } from '../../core';
import { detectLanguage } from '../../core/translation/language';
import { login } from '../../core/auth/login';
import { useNavigate } from 'react-router-dom';
import { useErrorToast } from '../../hooks/useErrorToast';
import { useStateContext } from '../../state/StateProvider';

export const Signup: FC = () => {
  const navigate = useNavigate();
  const errorToast = useErrorToast();
  const {state, dispatch} = useStateContext();

  const [competitions, setCompetitions] = useState<Competition[]>([]);
  const [selectedCompetitionIds, setSelectedCompetitionIds] = useState<number[]>([]);
  const [username, setUsername] = useState<string>('');
  const [email, setEmail] = useState<string>('');
  const [password, setPassword] = useState<string>('');

  useEffect(() => {
      CompetitionClient.fetchAll().then((resp) => {
        if (resp.error) {
          errorToast({});
          return;
        }
        setCompetitions(resp.response)
      })
  }, []);

  const addCompetitionId = (competitionId: number) => {
    if (selectedCompetitionIds.includes(competitionId)) {
        const arrayWithoutDeletedCompetition = selectedCompetitionIds.filter((id) => id !== competitionId);
        setSelectedCompetitionIds(arrayWithoutDeletedCompetition);
        return;
    }

    setSelectedCompetitionIds([...selectedCompetitionIds, competitionId]);
  }

  const createUser = async () => {
    const resp = await UserClient.create({
      name: username,
      email: email,
      password: password,
      preferredCompetitions: selectedCompetitionIds,
      language: detectLanguage()
    });

    if (resp.error) {
      errorToast({description: resp.error});
      return;
    }

    const loginResp = await login(email, password, dispatch);
    if (loginResp.error) {
      errorToast({description: resp.error});
    }

    navigate('/home');
  }

  return (
  <Flex direction={"column"} gap={14}>
    <Flex direction={'column'} gap={10}>
      <FullLogo/>
      <Flex direction={"column"} alignItems={"center"} gap={4} textAlign={"center"}>
        <Text fontSize={'2xl'}>{translate('SIGNUP_TEXT_MAIN')}</Text>
        <Text fontSize={'sm'}>{translate('SIGNUP_TEXT_SUB')}</Text>
      </Flex>
    </Flex>
    <Flex direction={"column"} gap={4}>
      <TextInput placeholder={translate('INTRO_USERNAME_PLACEHOLDER')} icon={'👱'} onChange={setUsername}/>
      <TextInput placeholder={translate('INTRO_EMAIL_PLACEHOLDER')} icon={'✉️'} onChange={setEmail}/>
      <TextInput placeholder={translate('INTRO_PASSWORD_PLACEHOLDER')} icon={'🛡️'} sensible onChange={setPassword}/>
    </Flex>

    <Flex direction={"column"} justifyContent={'center'} alignItems={'center'} gap={4}>
      <Text fontSize={'s'}>{translate('SIGNUP_CHOOSE_COMPETITIONS')}</Text>
      <HScroll

      >
        {competitions.map(c => (
          <Box key={c.id}>
            <CompetitionToggle id={c.id} name={c.name} flag={c.flag} isChecked={selectedCompetitionIds.includes(c.id)} onClick={() => addCompetitionId(c.id)}/>
          </Box>
          ))
        }
      </HScroll>
    </Flex>

    <Button type={'primary'} size={'full'} onClick={createUser}>{translate('SIGNUP_BUTTON_TEXT')}</Button>
    <Flex width="full"  position="fixed" bottom="0" zIndex="-1" left="0" justifyContent={"center"}>
      <Wave />
    </Flex>
  </Flex>
  )
}
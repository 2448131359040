import { ChallengerView, Division, RankedChallenger } from 'types';
import { ClientResponse, sendRequest } from './sendRequest';

export class ChallengerClient {
  public static async fetch(userId: number): Promise<ClientResponse<ChallengerView>> {
    const response = await sendRequest<{}, ChallengerView>(
      'GET',
      `challenger/${userId}`
    );

    if (response.error) {
      return {error: response.error} as ClientResponse<ChallengerView>;
    }

    return {error: '', response: response.response}
  }

  public static async fetchRanking(division: Division): Promise<ClientResponse<RankedChallenger[]>> {
    const response = await sendRequest<{}, RankedChallenger[]>(
      'GET',
      `challenger/ranking/${division}`
    );

    if (response.error) {
      return {error: response.error} as ClientResponse<RankedChallenger[]>;
    }

    return {error: '', response: response.response}
  }
}
import React, { FC } from 'react';
import { Flex } from '@chakra-ui/react';
import { IconLogo } from './IconLogo';
import { TextLogo } from './TextLogo';

export interface FullLogoProps {
  size?: 'sm' | 'md' | 'lg'
}

export const FullLogo: FC<FullLogoProps> = ({size}) => {
  return (
    <Flex justifyContent={"center"} alignItems={"center"} gap={size === 'lg' ? 4 : 2}>
    <Flex width={size ==='sm' ? '5%' : ( size === 'md' ? '8%' : '10%')}>
      <IconLogo/>
    </Flex>
    <TextLogo size={size}/>
  </Flex>
    );
}
import { FC } from 'react';
import { Flex, Text } from '@chakra-ui/react';

export interface TimerProps {
  time: number
}

export const Timer: FC<TimerProps> = ({time}) => {
  const getBackgroundColor = () => {
    if (time <= 3) {
      return 'red.700'
    }

    if (time <= 6 ) {
      return 'orange.900'
    }

    return 'orange.800'
  }
  return <Flex
    background={getBackgroundColor()}
    padding={3}
    rounded={'full'}
    width={'25px'}
    height={'25px'}
    justifyContent={'center'}
    alignItems={'center'}
  >
    <Text fontSize={'sm'}>{time}</Text>
  </Flex>
}
"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.Division = void 0;
exports.toDivision = toDivision;
var Division;
(function (Division) {
    Division["ROOKIE"] = "ROOKIE";
    Division["AMATEUR"] = "AMATEUR";
    Division["SEMI_PRO"] = "SEMI_PRO";
    Division["PRO"] = "PRO";
    Division["ALL_STAR"] = "ALL_STAR";
    Division["MVP"] = "MVP";
    Division["CHAMPION"] = "CHAMPION";
    Division["LEGEND"] = "LEGEND";
    Division["HALL_OF_FAME"] = "HALL_OF_FAME";
})(Division || (exports.Division = Division = {}));
function toDivision(value) {
    let division = value.toUpperCase();
    if (Object.values(Division).includes(division)) {
        return division;
    }
    throw new Error(`Invalid division: ${division}`);
}

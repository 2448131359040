import { FC } from 'react';
import { Input, InputGroup, InputLeftElement } from '@chakra-ui/react';

export interface TextInputProps {
  size?: "s" | "m" | "full",
  placeholder?: string;
  error?: boolean;
  icon?: string
  sensible?: boolean,
  onChange: (str: string) => void;
}

export const TextInput: FC<TextInputProps> = ({size, placeholder, error, icon, sensible, onChange}) => {
  const getSize = () => {
    if (size === 's') {
      return '30%'
    }

    if (size === 'm') {
      return '60%'
    }

    return '100%'
  }

  return <InputGroup>
    {icon && <InputLeftElement>{icon}</InputLeftElement>}
    <Input
      type={sensible ? 'password' : 'text'}
      width={getSize()}
      focusBorderColor={'orange.400'}
      placeholder={placeholder}
      borderColor={'orange.400'}
      errorBorderColor={'red.400'}
      background={'orange.900'}
      isInvalid={error}
      onChange={(event) => onChange(event.target.value)}
    />
  </InputGroup>
}
import { FC, useEffect, useRef, useState } from 'react';
import { QuickSessionResponse, QuickSessionStatus } from 'types';
import { ChallengerClient, QuickSessionClient } from '../../core';
import { useErrorToast } from '../../hooks/useErrorToast';
import { Flex } from '@chakra-ui/react';
import { getUserId } from '../../core/auth/auth';
import { Loading } from '../../components/base/Loading';
import { sleep } from '../../core/utils/sleep';
import { StateReducers } from '../../state/State';
import { useStateContext } from '../../state/StateProvider';
import { QuickSessionGameplay } from '../../components/gameplay/quickSession/QuickSessionGameplay';
import { QuickSessionSummary } from '../../components/gameplay/quickSession/QuickSessionSummary';

export const QuickSession: FC = () => {
  const errorToast = useErrorToast();
  const {state, dispatch} = useStateContext();

  const [session, setSession] = useState<QuickSessionResponse | null>(null);
  const [secondsToAnswer, setSecondsToAnswer] = useState<number | null>(null);
  const [isNextQuestionLoading, setIsNextQuestionLoading] = useState<boolean>(false);
  const [isQuickSessionLoading, setIsQuickSessionLoading] = useState<boolean>(true);
  const [showIsCorrect, setShowIsCorrect] = useState<boolean>(false);
  const [showIsFail, setShowIsFail] = useState<boolean>(false);
  const [showQuestionCard, setShowQuestionCard] = useState<boolean>(true);
  const [sessionEarnedPoints, setSessionEarnedPoints] = useState<number>(0);

  const [lastQuestionPoints, setLastQuestionPoints] = useState<number>(0);
  const questionTimerRef = useRef<NodeJS.Timer | null>(null);

  useEffect(() => {
    if (session === null) {
      startSession().then((session: QuickSessionResponse) => {
        setSession(session);
      });
    }

    return () => {
      if (questionTimerRef.current) {
        clearInterval(questionTimerRef.current);
      }
    };
  }, []);

  useEffect(() => {
    if (secondsToAnswer === 0) {
      answerQuestion('not_answered');
    }
  }, [secondsToAnswer]);

  useEffect(() => {
    if (!session) {
      return;
    }

    if (session.status === 'FINISHED') {
      endTimer();
    }

    const answeredQuestions = session.summary?.answeredQuestions;
    if (!answeredQuestions) {
      setSessionEarnedPoints(0);
      return;
    }

    const points = answeredQuestions.map(a => a.points);
    if (points.length == 0) {
      setSessionEarnedPoints(0);
      return;
    }

    if (points.length === 1) {
      setSessionEarnedPoints(points[0]);
      return;
    }

     setSessionEarnedPoints(points.reduce((a,b) => a + b));
  }, [JSON.stringify(session)]);

  const startTimer = () => {
    if (questionTimerRef.current) {
      clearInterval(questionTimerRef.current);
    }

    setSecondsToAnswer(10);
    questionTimerRef.current = setInterval(() => {
      setSecondsToAnswer((prevSeconds) => {
        if (prevSeconds === null) {
          return prevSeconds
        }
        const newSecondsToAnswer = Math.max(0, prevSeconds - 1);
        if (newSecondsToAnswer === 0 && questionTimerRef.current) {
          clearInterval(questionTimerRef.current);
        }
        return newSecondsToAnswer;
      });
    }, 1000);
  };

  const endTimer = () => {
    setSecondsToAnswer(null);
  }

  const startSession = async (): Promise<QuickSessionResponse> => {
    setIsQuickSessionLoading(true);
    const response = await QuickSessionClient.start();
    setIsQuickSessionLoading(false);

    if (response.error) {
      errorToast({description: response.error});
      throw new Error(response.error);
    }

    startTimer();
    return response.response;
  }

  const answerQuestion = async (answer: string): Promise<void> => {
    endTimer();
    if (session === null) {
      throw new Error('Session not initialized');
    }

    setIsNextQuestionLoading(true);
    const response = await QuickSessionClient.answer(session.id, answer);
    setIsNextQuestionLoading(false);

    if (response.error) {
      errorToast({description: response.error});
      throw new Error(response.error);
    }

    const updatedSession = response.response;

    const lastAnsweredQuestion = updatedSession.summary?.answeredQuestions[updatedSession.summary?.answeredQuestions.length - 1];
    if (!lastAnsweredQuestion) {
      return;
    }

    const isCorrectLastAnsweredQuestion = lastAnsweredQuestion.correctAnswer === lastAnsweredQuestion.answer;
    setLastQuestionPoints(lastAnsweredQuestion.points);

    if (isCorrectLastAnsweredQuestion) {
      setShowIsCorrect(true);
      await sleep(2000);
      setShowIsCorrect(false);
    }

    if (!isCorrectLastAnsweredQuestion) {
      setShowIsFail(true);
      await sleep(2000);
      setShowIsFail(false);
    }

    setShowQuestionCard(false);
    await sleep(100);
    setShowQuestionCard(true);
    startTimer();
    setSession(updatedSession);

    if (updatedSession.status === QuickSessionStatus.FINISHED) {
      const response = await ChallengerClient.fetch(getUserId());
      if (response.error) {
        return;
      }

      dispatch({type: StateReducers.SET_CHALLENGER, payload: response.response});
    }
  }

  return (
    <Flex direction={'column'} gap={8}>
      {isQuickSessionLoading && <Flex width={'full'} justifyContent={'center'}><Loading/></Flex>}
      {session && ['ONGOING', 'CREATED'].includes(session.status)
        ? <QuickSessionGameplay session={session} secondsToAnswer={secondsToAnswer} showQuestionCard={showQuestionCard} isNextQuestionLoading={isNextQuestionLoading} showIsCorrect={showIsCorrect} showIsFail={showIsFail} lastQuestionPoints={lastQuestionPoints} sessionEarnedPoints={sessionEarnedPoints} onAnswerQuestion={answerQuestion}/>
        : !isQuickSessionLoading && session && <QuickSessionSummary sessionEarnedPoints={sessionEarnedPoints} session={session}/> }
    </Flex>
  )
};
import React, { FC } from 'react';
import { Box } from '@chakra-ui/react';
import logo from '../../assets/images/logo.png';

export const IconLogo: FC = () => {
  return (
    <Box width={"100%"}>
      <img src={logo} alt='Legacy Football' />
    </Box>
  );
}
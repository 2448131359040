import React from 'react';
import { Route, Routes } from 'react-router-dom';
import { Intro } from '../views/Intro/Intro';
import { Protected } from './Protected';
import { getAuthToken } from '../core';
import { Signup } from '../views/Intro/Signup';
import { Signin } from '../views/Intro/Signin';
import { Home } from '../views/Home';
import { QuickSession } from '../views/Gameplay/QuickSession';
import { Ranking } from '../views/Ranking';

export const Router: React.FC = () => {
  const getHome = () => {
    if (!getAuthToken()) {
      return <Route path={"/"} element={<Intro/>}/>
    }

    return <Route path={'/'} element={<Protected element={<Home/>}/>}/>
  }
  return (
    <Routes>
      {/*Public routes*/}
      {getHome()}
      <Route path={"/intro"} element={<Intro/>}/>
      <Route path={"/signup"} element={<Signup/>}/>
      <Route path={"/signin"} element={<Signin/>}/>

      {/*Protected routes*/}
      <Route path={'/home'} element={<Protected element={<Home/>}/>}/>
      <Route path={'/quick-session'} element={<Protected element={<QuickSession/>}/>}/>
      <Route path={'/ranking/:division'} element={<Protected element={<Ranking/>}/>}/>

    </Routes>
  )
}
import { FC, ReactNode, useRef } from 'react';
import { Flex } from '@chakra-ui/react';

export interface HScrollProps {
  children: ReactNode;
}

export const HScroll: FC<HScrollProps> = ({children}) => {
  const scrollContainerRef = useRef(null);

  const handleMouseDown = (event: MouseEvent) => {
    if (!scrollContainerRef) {
      return;
    }

    const container: any = scrollContainerRef.current;
    container.isDragging = true;
    container.startX = event.pageX - container.offsetLeft;
  };

  const handleMouseMove = (event: MouseEvent) => {
    if (!scrollContainerRef) {
      return;
    }

    const container: any = scrollContainerRef.current;
    if (!container.isDragging) return;
    event.preventDefault();
    const x = event.pageX - container.offsetLeft;
    const walk = (x - container.startX) * 0.1;
    container.scrollLeft = container.scrollLeft - walk;
  };

  const handleMouseUp = () => {
    if (!scrollContainerRef) {
      return;
    }

    const container: any = scrollContainerRef.current;
    container.isDragging = false;
  };

  return (
      <Flex
        ref={scrollContainerRef}
        width="100%"
        overflowX="auto"
        overflowY="hidden"
        whiteSpace="nowrap"
        flexWrap="nowrap"
        gap={3}
        css={{
          "&::-webkit-scrollbar": {
            display: "none",
          },
          "-ms-overflow-style": "none",
          "scrollbar-width": "none",
          "@media (min-width: 1000px)": {
            maxWidth: "550px",
          },
          "@media ( max-width: 999px)": {
            maxWidth: "500px",
          },
          "@media ( max-width: 600px)": {
            maxWidth: "500px",
          },
          "@media (max-width: 500px)": {
            maxWidth: "400px",
          },
          "@media (max-width: 400px)": {
            maxWidth: "300px",
          },
          "@media (max-width: 300px)": {
            maxWidth: "200px",
          },
        }}
        onMouseDown={handleMouseDown as any}
        onMouseMove={handleMouseMove as any}
        onMouseUp={handleMouseUp}
        onMouseLeave={handleMouseUp}
      >
        {children}
      </Flex>
  )
}
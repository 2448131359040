import { FC } from 'react';
import { Flex, Text } from '@chakra-ui/react';
import { Division } from 'types';
import { translate } from '../../core/translation';

export interface RankedChallengerRowProps {
  position: number,
  name: string,
  points: number,
  active: boolean,
  division?: Division
}

export const RankedChallengerRow: FC<RankedChallengerRowProps> = ({position, name, points, active, division}) => {
  const getBackground = () => {
    if (active) {
      return 'linear-gradient(90deg, orange.400 0%, orange.700 100%)';
    }

    return 'linear-gradient(90deg, blue.400 0%, blue.700 100%)';
  }

  const getPositionBadgeBackground = () => {
    if (position === 1) {
      return 'linear-gradient(90deg, yellow.400 0%, yellow.700 100%)';
    }

    if (position === 2) {
      return 'linear-gradient(90deg, gray.300 0%, gray.500 100%)';
    }

    if (position === 3) {
      return 'linear-gradient(90deg, orange.300 0%, orange.600 100%)';
    }

    return 'linear-gradient(90deg, gray.400 0%, gray.700 100%)';
  }
  return (<Flex
    width={'100%'}
    padding={3}
    borderRadius={'xl'}
    bgGradient={getBackground()}
  >
    <Flex width={'100%'} justifyContent={'space-between'}>
      <Flex alignItems={'center'} gap={2}>
        {position > 0 && <Flex padding={2} borderRadius={'100%'} height='30px' width={'30px'} bgGradient={getPositionBadgeBackground()} justifyContent={'center'} alignItems={'center'}>
          <Text fontSize={'sm'} textShadow="0 0 3px black, 1px 1px 0 black, -1px -1px 0 black, 1px -1px 0 black, -1px 1px 0 black">{position}</Text>
        </Flex>}
        <Text
          fontSize={'smaller'}
          textShadow="0 0 3px black, 1px 1px 0 black, -1px -1px 0 black, 1px -1px 0 black, -1px 1px 0 black"
        >
          {division ? `${name} · ${translate(division)}` : name}
        </Text>
      </Flex>
      <Flex gap={2} alignItems={'center'}>
        <Text fontSize={'sm'} textShadow="0 0 3px black, 1px 1px 0 black, -1px -1px 0 black, 1px -1px 0 black, -1px 1px 0 black">🏆</Text>
        <Text fontSize={'sm'} textShadow="0 0 3px black, 1px 1px 0 black, -1px -1px 0 black, 1px -1px 0 black, -1px 1px 0 black">{points}</Text>
      </Flex>
    </Flex>
  </Flex>)
}
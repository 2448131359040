"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.QuestionCategory = void 0;
var QuestionCategory;
(function (QuestionCategory) {
    QuestionCategory["TEAM_HISTORY"] = "TEAM_HISTORY";
    QuestionCategory["ASSISTANTS"] = "ASSISTANTS";
    QuestionCategory["WARRIORS"] = "WARRIORS";
    QuestionCategory["SCORERS"] = "SCORERS";
})(QuestionCategory || (exports.QuestionCategory = QuestionCategory = {}));

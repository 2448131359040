import React from 'react';
import { Box, Center, ChakraProvider, Flex } from '@chakra-ui/react';
import theme from './theme';
import { Router } from './router/Router';
import { StateProvider } from './state/StateProvider';
import { Header } from './components/Header';

function App() {

  return (
    <ChakraProvider theme={theme}>
        <StateProvider>
          <Flex direction={'column'} width={'100%'} alignItems={'center'} justifyContent={'center'} overflowX={'hidden'}>
            <Header/>
            <Flex maxWidth="600px" width="100%" justifyContent={'center'} alignItems={'center'} padding={4} overflowX={'hidden'}>
              <Router/>
            </Flex>
          </Flex>
        </StateProvider>
    </ChakraProvider>
  );
}

export default App;

import { ChallengerView } from 'types';
import { useContext } from 'react';

export type State = {
  challenger?: ChallengerView
}

export function initState(): State {
  return {};
}

export enum StateReducers {
  'SET_CHALLENGER' = 'SET_CHALLENGER',
}

export type Action = {
  type: StateReducers,
  payload: any
}

export const stateReducer = (state: State, action: Action): State => {
  switch (action.type) {
    case StateReducers.SET_CHALLENGER:
      return { ...state, challenger: action.payload };
    default:
      return state;
  }
}
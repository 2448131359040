import React, { FC, useEffect, useState } from 'react';
import { Flex } from '@chakra-ui/react';
import { Card } from '../components/base/Card';
import { Link, useNavigate } from 'react-router-dom';
import { translate } from '../core/translation';
import { sendRequest } from '../core';
import { GetRandomFactArgs } from 'types';
import { detectLanguage } from '../core/translation/language';
import { useStateContext } from '../state/StateProvider';
import { TranslationKeys } from '../core/translation/keys/keys';

export const Home: FC = () => {
  const navigate = useNavigate();
  const [fact, setFact] = useState<string | null>(null);
  const {state, dispatch} = useStateContext();

  useEffect(() => {
    fetchRandomFact();
    const intervalId = setInterval(fetchRandomFact, 45000);
    return () => clearInterval(intervalId);
  }, []);

  const fetchRandomFact = async (): Promise<void> => {
    const response = await sendRequest<GetRandomFactArgs, string>(
      'GET',
      `fact/random?lang=${detectLanguage()}`,
    );

    if (response.error) {
      throw new Error(response.error);
    }

    setFact(response.response)
  }

  const onRankingClicked = () => {
    navigate(`ranking/${getDivision()}`)
  }

  const getDivision = () => {
    return state.challenger?.division;
  }
  return (
    <Flex direction="column" alignItems="center" justifyContent="space-between" gap={6} width={'100%'}>
      <Link to={'/quick-session'}>
        <Card
          headerText={translate('QUICK_SESSION')}
          bodyText={translate('QUICK_SESSION_CARD_DESCRIPTION')}
          bgColor={'orange.600'}
          bgColorLinear={'orange.400'}
          icon={"⏱"}
          cta={translate('QUICK_SESSION_CARD_CTA')}
        />
      </Link>

      {getDivision() &&
        <Card
        headerText={`${translate('RANKING')} · ${translate(getDivision() as keyof TranslationKeys)}`}
        bodyText={translate('RANKING_CARD_DESCRIPTION', [translate((getDivision() || '') as keyof TranslationKeys)])}
        bgColor={'green.600'}
        bgColorLinear={'green.400'}
        icon={'🏆'}
        cta={translate('RANKING_CARD_CTA')}
        onClick={() => onRankingClicked()}
      />}

      { fact && <Flex width={'100%'}>
        <Card
          headerText={translate('FACT_CARD_HEADER')}
          bodyText={fact}
          bgColor={'blue.500'}
          bgColorLinear={'blue.700'}
          icon={'🤓'}
        />
      </Flex>}
    </Flex>
  );
}
export type Language = 'spanish' | 'english';

export const detectLanguage = (): Language => {
  const language = navigator.languages && navigator.languages.length
    ? navigator.languages[0]
    : navigator.language;

  if (language.startsWith('es')) {
    return 'spanish';
  } else {
    return 'english';
  }
};
import React from 'react';
import { Navigate } from 'react-router-dom';
import { getAuthToken } from '../core';
import { Header } from '../components/Header';

export interface ProtectedProps {
  element: any;
}

export const Protected: React.FC<ProtectedProps> = ({element}) => {
  if (!getAuthToken()) {
    return <Navigate to={'/intro'}/>
  }

  return <>
    {element}
  </>;
}
import React, { FC } from 'react';
import { Text } from '@chakra-ui/react';

export interface TextLogoProps  {
  size?: 'sm' | 'md' | 'lg'
}

export const TextLogo: FC<TextLogoProps> = ({size}) => {
  return (
    <Text
      fontSize={size === 'sm' ? 'md' : (size === 'md' ? 'xl' : '3xl')}
      fontWeight={700}
      fontStyle={'italic'}
    >
      Legacy Football.
    </Text>
  );
}
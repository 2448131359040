import { FC, useEffect, useState } from 'react';
import { Flex, Text } from '@chakra-ui/react';
import { useNavigate, useParams } from 'react-router-dom';
import { Division, DivisionThreshold, RankedChallenger, toDivision } from 'types';
import { ChallengerClient } from '../core';
import { useErrorToast } from '../hooks/useErrorToast';
import { RankedChallengerRow } from '../components/challenger/RankedChallengerRow';
import { useStateContext } from '../state/StateProvider';
import { translate } from '../core/translation';
import { TranslationKeys } from '../core/translation/keys/keys';
import { sleep } from '../core/utils/sleep';
import { Loading } from '../components/base/Loading';

export const Ranking: FC = () => {
  const navigate = useNavigate();
  const { division } = useParams<{ division: string }>();
  const {state, dispatch} = useStateContext();
  const [isRankingLoading, setIsRankingLoading] = useState<boolean>(true);
  const errorToast = useErrorToast();

  const [ranking, setRanking] = useState<RankedChallenger[]>([]);

  useEffect(() => {
    setIsRankingLoading(true);
    ChallengerClient.fetchRanking(toDivision(division || '')).then((r) => {
      if (r.error) {
        errorToast({description: r.error});
        throw new Error(r.error);
      }
      setIsRankingLoading(false);
      setRanking(r.response);
    });
  }, []);

  useEffect(() => {
    setIsRankingLoading(true);
    ChallengerClient.fetchRanking(toDivision(division || '')).then((r) => {
      if (r.error) {
        errorToast({description: r.error});
        throw new Error(r.error);
      }

      setIsRankingLoading(false);
      setRanking(r.response);
    });
  }, [division]);

  function getPreviousDivision(division: Division): Division | null {
    const divisions = Object.keys(DivisionThreshold) as Division[];
    const currentIndex = divisions.indexOf(division);

    if (currentIndex > 0) {
      return divisions[currentIndex - 1];
    }

    return null;
  }

  function getNextDivision(division: Division): Division | null {
    const divisions = Object.keys(DivisionThreshold) as Division[];
    const currentIndex = divisions.indexOf(division);

    if (currentIndex < divisions.length - 1) {
      return divisions[currentIndex + 1];
    }

    return null;
  }

  const goToDivision = (division: Division) => {
    navigate(`/ranking/${division}`)
  }

  return (
    <Flex width={'100%'} position={'relative'} direction={'column'} justifyContent={'center'} alignItems={'center'} paddingY={2}>
      {isRankingLoading && <Loading/>}
      {!isRankingLoading && <Flex width={'100%'} direction={'column'} justifyContent={'center'} alignItems={'center'} marginBottom={12} gap={4}>
        {division && <Flex width={'100%'} alignItems={'center'} justifyContent={'space-between'}>
          <Flex width={'33%'} textAlign={'center'} justifyContent={'start'}>{getPreviousDivision(division as Division) ? <Text onClick={() => goToDivision(getPreviousDivision(division as Division) as Division)} cursor={'pointer'} fontSize={'smaller'} textShadow="0 0 3px black, 1px 1px 0 black, -1px -1px 0 black, 1px -1px 0 black, -1px 1px 0 black">{`👈 ${translate(getPreviousDivision(division as Division) as keyof TranslationKeys)}`}</Text> : <Flex></Flex>}</Flex>
          <Flex width={'34%'} textAlign={'center'} justifyContent={'center'}><Text fontSize={'xl'} textShadow="0 0 3px black, 1px 1px 0 black, -1px -1px 0 black, 1px -1px 0 black, -1px 1px 0 black">{translate(division as keyof TranslationKeys)}</Text></Flex>
          <Flex width={'33%'} textAlign={'center'} justifyContent={'end'}>{getNextDivision(division as Division) ? <Text onClick={() => goToDivision(getNextDivision(division as Division) as Division)} cursor={'pointer'} fontSize={'smaller'} textShadow="0 0 3px black, 1px 1px 0 black, -1px -1px 0 black, 1px -1px 0 black, -1px 1px 0 black">{`${translate(getNextDivision(division as Division) as keyof TranslationKeys)} 👉`}</Text> : <Flex></Flex>}</Flex>
        </Flex>}
        <Flex width={'100%'} direction={'column'} gap={2}>
          {ranking.map((challenger: RankedChallenger, i: number) => <RankedChallengerRow key={i} name={challenger.challenger.name} position={challenger.position} points={challenger.challenger.points} active={state.challenger?.id === challenger.challenger.id}/>)}
        </Flex>
      </Flex>}
      {state.challenger && <Flex
        width="100%"
        position="fixed"
        bottom="0"
        left={0}
        right={0}
        zIndex="10"
      >
        <RankedChallengerRow position={0} name={state.challenger?.name} points={state.challenger?.points} active={true} division={state.challenger.division}/>
      </Flex>}
    </Flex>
  );

}
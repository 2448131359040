import { ClientResponse, sendRequest } from './sendRequest';
import { AnswerQuickSessionArgs, QuickSessionResponse, StartQuickSessionArgs } from 'types';
import { getUserId } from '../auth/auth';

export class QuickSessionClient {
  public static async start(): Promise<ClientResponse<QuickSessionResponse>> {
    const response = await sendRequest<StartQuickSessionArgs, QuickSessionResponse>(
      'POST',
      'gameplay/quick-session/start',
      {
        userId: getUserId(),
        additionalChoices: 1,
        rounds: 10,
        timeLimitMargin: 40,
        timeLimitPerRound: 40
      }
    );

    if (response.error) {
      return { error: response.error } as ClientResponse<QuickSessionResponse>;
    }

    return {error: '', response: response.response}
  }

  public static async answer(sessionId: number, answer: string): Promise<ClientResponse<QuickSessionResponse>> {
    const response = await sendRequest<AnswerQuickSessionArgs, QuickSessionResponse>(
      'POST',
      'gameplay/quick-session/answer',
      {
        userId: getUserId(),
        quickSessionId: sessionId,
        answer
      }
    );

    if (response.error) {
      return { error: response.error } as ClientResponse<QuickSessionResponse>;
    }

    return {error: '', response: response.response}
  }
}
import React, { FC, useEffect } from 'react';
import { Box, Flex, Text } from '@chakra-ui/react';
import { FullLogo } from './base';
import { useLocation, useNavigate } from 'react-router-dom';
import { useStateContext } from '../state/StateProvider';
import { getUserId, isAuth } from '../core/auth/auth';
import { sendRequest } from '../core';
import { ChallengerView } from 'types';
import { StateReducers } from '../state/State';
import { translate } from '../core/translation';
import { formatNumber } from '../core/utils/formatNumber';

export const Header: FC = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const {state, dispatch} = useStateContext();

  async function fetchChallenger() {
    if (!isAuth()) {
      return;
    }

    const userId = getUserId();
    const response = await sendRequest<{}, ChallengerView>(
      'GET',
      `challenger/${userId}`
    );

    if (response.error) {
      return;
    }

    dispatch({type: StateReducers.SET_CHALLENGER, payload: response.response});
  }

  useEffect(() => {
    fetchChallenger();
  }, []);
  const showBackArrow = () => {
    return !['/', '/home'].includes(location.pathname)
  }

  const goBack = () => {
    navigate('/');
  }

  const points = (): number => {
    return state.challenger?.points || 0;
  }

  return state.challenger &&
    <Flex
    width={'100%'}
    justifyContent={'space-between'}
    paddingY={2}
    paddingX={4}
    alignItems={'center'}
    borderBottom={'4px solid'}
    background={'gray.800'}
    borderBottomColor={'gray.800'}
    maxHeight={'80px'}
  >
    <Flex width={'20%'}> {showBackArrow() &&
      <Box
        onClick={() => goBack()}
        cursor={'pointer'}
        textTransform={'uppercase'}
      >
        <Text fontSize={'smaller'}>{translate('BACK')}</Text>
       </Box>} </Flex>
    <Flex width={'60%'} maxWidth={'200px'}><FullLogo size={'md'}/></Flex>
    <Flex width={'20%'} justifyContent={'end'}>
      <Flex padding={2} background={'gray.800'} rounded={'2xl'} gap={2}>
        <Text fontSize={'smaller'}>🏆</Text>
        <Text fontSize={'smaller'} onClick={() => fetchChallenger()}>{formatNumber(points())}</Text>
      </Flex>
    </Flex>
  </Flex>
}
import React, { FC, useState } from 'react';
import { Flex, Text } from '@chakra-ui/react';
import { translate } from '../../core/translation';
import { Button, FullLogo, TextInput } from '../../components/base';
import { Wave } from '../../components/utils';
import { login } from '../../core/auth/login';
import { useNavigate } from 'react-router-dom';
import { useErrorToast } from '../../hooks/useErrorToast';
import { useStateContext } from '../../state/StateProvider';

export const Signin: FC = () => {
  const navigate = useNavigate()
  const errorToast = useErrorToast();
  const {state, dispatch} = useStateContext();

  const [email, setEmail] = useState<string>('');
  const [password, setPassword] = useState<string>('');

  const signinUser = async () => {
    const resp = await login(email, password, dispatch);
    if (resp.error) {
      console.log(resp.error);
      errorToast({
        description: resp.error
      });
      return;
    }

    navigate('/home');
  }

  return (
    <Flex direction={'column'} gap={14}>
      <FullLogo/>
      <Flex direction={'column'} alignItems={'center'} gap={4} textAlign={'center'}>
        <Text fontSize={'2xl'}>{translate('SIGNIN_TEXT_MAIN')}</Text>
        <Text fontSize={'sm'}>{translate('SIGNIN_TEXT_SUB')}</Text>
      </Flex>
      <Flex direction={'column'} gap={4}>
        <TextInput placeholder={translate('INTRO_EMAIL_PLACEHOLDER')} icon={'✉️'} onChange={setEmail}/>
        <TextInput placeholder={translate('INTRO_PASSWORD_PLACEHOLDER')} icon={'🛡️'} sensible onChange={setPassword}/>
      </Flex>
      <Button type={'primary'} size={'full'} onClick={signinUser}>{translate('SIGNIN_BUTTON_TEXT')}</Button>
      <Flex width="full"  position="fixed" bottom="0" zIndex="-1" left="0" justifyContent={"center"}>
        <Wave />
      </Flex>
    </Flex>
  );
}
import { FC } from 'react';
import { Box, Flex, Spinner } from '@chakra-ui/react';
import { IconLogo } from './IconLogo';

export const Loading: FC = () => {

  return   <Flex width={'100%'} maxWidth={'20px'} justifyContent="center" alignItems="center" position="relative">
    <Spinner
      size="xl"
      position="absolute"
      thickness='4px'
      speed='0.65s'
      emptyColor='gray.700'
      color='orange.500'
    />
    <Box position="relative" zIndex="1">
      <IconLogo />
    </Box>
  </Flex>
}
import { getAuthToken } from './getAuthToken';
import {jwtDecode} from 'jwt-decode';

export const isAuth = (): boolean => {
  return !!getAuthToken();
}

export const getUserId = (): number => {
  if (!isAuth()) {
    throw new Error('User not authenticated');
  }

  const decodedToken = jwtDecode(getAuthToken() || '');
  return (decodedToken as {userId: number}).userId;
}
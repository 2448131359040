import { extendTheme, ThemeConfig } from '@chakra-ui/react';

const config: ThemeConfig = {
  initialColorMode: "dark"
}

const theme = extendTheme({
  config,
  styles: {
    global: {
      body: {
        bg: "gray.700",
        color: "gray.300",
        fontWeight: "600"
      }
    }
  }
});

export default theme;